
  import { Component, Mixins } from "vue-property-decorator";
  import { Getter, Action, Mutation } from "vuex-class";
  import { CanGenerateTimelineEntries } from "@/mixins/can-generate-timeline-entries";
  import { HasTaskActions } from "@/mixins/has-task-actions";

  // Types
  import { IndexTaskPayload, Task, TaskPhase } from "@/store/modules/task.store";
  import { IndexAccountPayload } from "@/store/modules/account.store";
  import { IndexDocumentPayload } from "@/store/modules/document.store";
  import { Seller } from "@/store/modules/seller.store";
  import { activityIsDossier } from "@/utils/model-helpers";
  import { TeamFunctions } from "@/store/modules/activity-account.store";
import { WorkStatus } from '@/store/modules/activity.store';

  interface TaskFilter {
    search: string;
    users: Array<{ id: number }>;
    range: {
      from: string;
      to: string;
    };
  }

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
      TaskDeleteModal: () => import("@/components/modals/delete-task.vue"),
      TasksRegenerateModal: () => import("@/components/modals/regenerate-tasks.vue"),
      UserImageWrapper: () => import("@/components/general/user-image-wrapper.vue"),
      UserSelect: () => import("@/components/forms/user-seller-select.vue"),
      UserImage: () => import("@/components/general/user-image.vue"),
      FormDateRangePicker: () => import("@/components/forms/date-range.vue"),
      TaskSettings: () => import("@/components/dossier/tasks/settings.vue"),
      TaskGroup: () => import("@/components/dossier/tasks/group.vue"),
      TaskSelectToast: () => import("@/components/task/select-toast.vue"),
    },
  })
  export default class DossierTabTasks extends Mixins(CanGenerateTimelineEntries, HasTaskActions) {
    @Getter("auth/isAdmin") isAdmin!: boolean;
    @Getter("activity/viewing") activity!: Activity;
    @Getter("task/all") tasks!: Task[];
    @Getter("seller/all") sellers!: Seller[];

    @Mutation("task/RESET") resetTasks!: () => void;
    @Mutation("task/UPDATE_MODEL") mutateTask!: (payload: Task) => void;

    @Action("task/index") indexTasks!: (payload: IndexTaskPayload) => Promise<Task[]>;
    @Action("account/indexForCurrentOffice") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;
    @Action("document/index") indexDocuments!: (payload: IndexDocumentPayload) => Promise<Document[]>;

    loading: boolean = false;

    accounts: Account[] = [];

    documents: Document[] = [];

    filter: TaskFilter = {
      search: "",
      users: [],
      range: {
        from: "",
        to: "",
      },
    };

    displayGroups: { [key: string]: boolean } = { Reminders: true };

    get searchedTasks(): { [key: string]: Task[] } {
      const filterUsers = this.filter.users.map((u: { id: number }) => u.id);

      const filteredTasks = this.tasks.filter((task) => {
        const matchesRange =
          (!this.filter.range.from && !this.filter.range.to) ||
          (task.deadline && new Date(task.deadline) >= new Date(this.filter.range.from) && new Date(task.deadline) <= new Date(this.filter.range.to));
        const matchesAssignee = filterUsers.length === 0 || (task.assigned_to.length && filterUsers.includes(task.assigned_to[0].id));
        const matchesSearch = task.title.toLowerCase().includes(this.filter.search.toLowerCase());

        return matchesSearch && matchesAssignee && matchesRange;
      });

      if (!this.isDossier) {
        return {
          Reminders: filteredTasks,
        };
      }

      let groupedResults: any = {};
      filteredTasks.forEach((task: Task) => {
        if (!groupedResults[task.group ?? "Ongegroepeerd"]) {
          groupedResults[task.group ?? "Ongegroepeerd"] = {};
        }
        if (!groupedResults[task.group ?? "Ongegroepeerd"][task.category ?? "Ongegroepeerd"]) {
          groupedResults[task.group ?? "Ongegroepeerd"][task.category ?? "Ongegroepeerd"] = [];
        }

        groupedResults[task.group ?? "Ongegroepeerd"][task.category ?? "Ongegroepeerd"].push(task);
      });

      return groupedResults;
    }

    get users() {
      const accounts = this.accounts.map((a) => {
        return { first_name: a.first_name, last_name: a.last_name, id: a.id, value: a.id, media: a.media, type: "user" };
      });

      const sellers = this.sellers.map((s) => {
        return { first_name: s.first_name, last_name: s.last_name, id: s.id, value: s.id, media: null, type: "seller" };
      });

      return [...accounts, ...sellers];
    }

    get isMobile() {
      // @ts-ignore
      return this.$root.isMobile;
    }

    get isDossier() {
      return activityIsDossier(this.activity);
    }

    get defaultAssignee() {
      if (this.isDossier) {
        return null;
      }

      return this.activity.assigned_to.find((a) => a.type === TeamFunctions.buyer);
    }

    async mounted() {
      this.resetTasks();

      this.loading = true;

      this.indexAccounts({ q: { or: { roles: ["administrator", "user"] } }, per_page: 99 }).then((a) => (this.accounts = a));

      this.indexDocuments({ per_page: 250, q: { and: { activity_id: this.activity.id } } }).then((d) => (this.documents = d));

      const query: any = { and: { activity_id: this.activity.id } };

      if (this.isDossier && this.activity.work_status === WorkStatus.archived) {
        query.and.not = {
          phase: [TaskPhase.prospect],
          group: ["Reminders", "Follow-up", "Overige"],
        };
      } else if (this.isDossier) {
        query.and.not = {
          phase: [TaskPhase.prospect, TaskPhase.dossierClosing],
          group: ["Reminders", "Follow-up"],
        };
      } else {
        query.and.or = {
          phase: "PROSPECT",
          group: ["Reminders", "Follow-up", "Overige"],
        };
      }

      await this.indexTasks({ q: query, per_page: 900, s: { ordinal: "asc", created_at: "asc", id: "asc" } });

      this.loading = false;
    }

    toggleGroup(group: string) {
      this.displayGroups[group] = !this.displayGroups[group] ?? true;

      this.$forceUpdate();
    }    
  }
