import { Component, Mixins } from "vue-property-decorator";
import { Getter, Action, Mutation } from "vuex-class";

import { IndexTaskPayload, PatchTaskPayload, PatchBulkTaskPayload, Task, TaskAssignee, TaskStatus, TaskPhase } from "@/store/modules/task.store";
import { CreateAccountTaskPayload, PatchAccountTaskPayload, AccountTask } from "@/store/modules/account-task.store";
import { CreateDocumentTaskPayload, PatchDocumentTaskPayload, DocumentTask } from "@/store/modules/document-task.store";
import { CreateSellerTaskPayload, PatchSellerTaskPayload, SellerTask } from "@/store/modules/seller-task.store";
import { HasFormErrors } from "@/mixins/has-form-errors";

@Component
export class HasTaskActions extends Mixins(HasFormErrors) {
  @Action("account-task/create") createAccountTask!: (payload: CreateAccountTaskPayload) => Promise<AccountTask[]>;
  @Action("account-task/patch") patchAccountTask!: (payload: PatchAccountTaskPayload) => Promise<AccountTask[]>;
  @Action("account-task/delete") deleteAccountTask!: (payload: number) => Promise<AccountTask>;
  @Action("seller-task/create") createSellerTask!: (payload: CreateSellerTaskPayload) => Promise<SellerTask[]>;
  @Action("seller-task/patch") patchSellerTask!: (payload: PatchSellerTaskPayload) => Promise<SellerTask[]>;
  @Action("seller-task/delete") deleteSellerTask!: (payload: number) => Promise<SellerTask>;
  @Action("document-task/create") createDocumentTask!: (payload: CreateDocumentTaskPayload) => Promise<DocumentTask[]>;
  @Action("document-task/patch") patchDocumentTask!: (payload: PatchDocumentTaskPayload) => Promise<DocumentTask[]>;
  @Action("task/patchBulk") patchBulk!: (payload: PatchBulkTaskPayload) => Promise<Task[]>;
  @Action("task/patch") patchTask!: (payload: PatchTaskPayload) => Promise<Task>;

  bulkUpdating: boolean = false;
  checkedTasks: number[] = [];
  hoveringTasks: number[] = [];
  hoveringType: string | null = "";

  isCheckedTask(taskId: number) {
    return this.checkedTasks.includes(taskId);
  }

  clearCheckedTasks() {
    this.checkedTasks = [];
    this.hoveringTasks = [];
    this.hoveringType = null;

    //@ts-ignore
    if (this.$refs.group && this.$refs.group.length > 0) {
      //@ts-ignore
      this.$refs.group.forEach((group) => group.clearGroupChecked());
    }
  }

  handleTaskDeleted(taskId: number) {}

  async handleStatusChanged(payload: { status: TaskStatus; id: number }) {
    try {
      if (this.checkedTasks.length > 0 && this.checkedTasks.includes(payload.id)) {
        await this.handleBulkPatchTasks(payload);
      } else {
        await this.patchTask(payload);
      }

      this.$toast.open({ message: this.$t("views.dossier.task_status_changed") as string, type: "success", position: "bottom-right" });
    } catch (e) {
      this.errorResponse = this.formatErrors(e);
    }
  }

  async handleDateChanged(payload: { deadline: string; id: number }) {
    try {
      let updatedTasks = [];
      if (this.checkedTasks.length > 0 && this.checkedTasks.includes(payload.id)) {
        updatedTasks = await this.handleBulkPatchTasks(payload) as Task[];
      } else {
        updatedTasks = [await this.patchTask(payload)];
      }

      this.$toast.open({ message: this.$t("views.dossier.task_deadline_changed") as string, type: "success", position: "bottom-right" });
      return updatedTasks;
    } catch (e) {
      this.errorResponse = this.formatErrors(e);
    }
  }

  async handleAssigneeChanged(payload: { assignees: { id: number; type: "user" | "seller" }[]; id: number; seller_assigned_to: TaskAssignee[]; assigned_to: TaskAssignee[] }) {
    const newAssignee = payload.assignees[0];

    if (this.checkedTasks.length > 0 && newAssignee && this.checkedTasks.includes(payload.id)) {
      this.handleBulkPatchTasks(newAssignee.type === "user" ? { user: newAssignee.id } : { seller: newAssignee.id });
      return;
    }

    if (!newAssignee || newAssignee.type === "user") {
      for (const at of payload.seller_assigned_to) {
        await this.deleteSellerTask(at.relation_id);
      }
    }

    if (!newAssignee || newAssignee.type === "seller") {
      for (const at of payload.assigned_to) {
        await this.deleteAccountTask(at.relation_id);
      }
    }
    if (!newAssignee) {
      return;
    }

    const assignee: { id: number; relation_id: number } | undefined = newAssignee.type === "user" ? payload.assigned_to[0] : payload.seller_assigned_to[0];
    if (assignee) {
      if (newAssignee.type === "user") {
        await this.patchAccountTask({ account_id: newAssignee.id, id: assignee!.relation_id });
      }
      if (newAssignee.type === "seller") {
        await this.patchSellerTask({ seller_id: newAssignee.id, id: assignee!.relation_id });
      }

      this.$toast.open({ message: this.$t("views.dossier.task_assignee_changed") as string, type: "success", position: "bottom-right" });
      return;
    }

    if (newAssignee.type === "user") {
      await this.createAccountTask({ account_id: newAssignee.id, task_id: payload.id });
    }

    if (newAssignee.type === "seller") {
      await this.createSellerTask({ seller_id: newAssignee.id, task_id: payload.id });
    }

    this.$toast.open({ message: this.$t("views.dossier.task_assignee_changed") as string, type: "success", position: "bottom-right" });
  }

  async handleDocumentChanged(payload: { documents: number[]; task: Task; activity: { id: number } }) {
    let assignedDocument: { id: number; relation_id: number } | undefined;

    if (payload.task.assigned_to.length) {
      assignedDocument = payload.task.documents[0];
    }

    if (assignedDocument) {
      payload.documents.forEach(async (document_id) => {
        await this.patchDocumentTask({ document_id, id: assignedDocument!.relation_id });
      });
      this.$toast.open({ message: this.$t("views.dossier.task_document_changed") as string, type: "success", position: "bottom-right" });
      return;
    }

    payload.documents.forEach(async (document_id) => {
      await this.createDocumentTask({ document_id, task_id: payload.task.id, activity_id: payload.activity.id });
    });

    this.$toast.open({ message: this.$t("views.dossier.task_document_changed") as string, type: "success", position: "bottom-right" });
  }

  handleTaskCheckChanged(taskId: number) {
    if (this.checkedTasks.includes(taskId)) {
      this.checkedTasks = this.checkedTasks.filter((item) => item !== taskId).filter(Boolean);
    } else {
      this.checkedTasks.push(taskId);
    }
  }

  handleBulkCheckTasks(taskids: number[]) {
    taskids.forEach((taskId) => {
      if (!this.checkedTasks.includes(taskId) && taskId) {
        this.checkedTasks.push(taskId);
      }
    });
  }

  handleBulkUncheckTasks(taskids: number[]) {
    this.checkedTasks = this.checkedTasks.filter((item) => !taskids.includes(item)).filter(Boolean);
  }

  async handleBulkPatchTasks(payload: { status?: TaskStatus; deadline?: string; user?: number; seller?: number }) {
    const value = payload.status ? payload.status : payload.deadline ? payload.deadline : payload.user ? payload.user : payload.seller;

    if (!value) {
      return;
    }

    const bulkPayload = {
      taskIds: this.checkedTasks,
      field: payload.status ? "status" : payload.deadline ? "deadline" : payload.user ? "user" : "seller",
      value: value,
    };

    this.bulkUpdating = true;

    const updatedTasks = await this.patchBulk(bulkPayload);

    this.bulkUpdating = false;

    return updatedTasks;
  }

  mouseoverTask(payload: { taskId: number; type: string }) {
    if (!this.hoveringTasks.includes(payload.taskId)) {
      this.hoveringTasks.push(payload.taskId);
    }
    this.hoveringType = payload.type;
  }

  mouseleaveTask(payload: { taskId: number; type: string }) {
    if (this.hoveringTasks.includes(payload.taskId)) {
      this.hoveringTasks = this.hoveringTasks.filter((item) => item !== payload.taskId);
    }
    this.hoveringType = null;
  }
}
